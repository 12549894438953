<template>
  <q-card
    bordered
    class="col-3 row q-py-sm q-px-md grow card"
    :style="`min-height: 100px; border-color: ${data.color}`"
  >
    <q-card-section :style="{ color: data.color }" class="col-12 row q-pa-sm text-bold justify-between">
      <p>{{ data.title }}</p>
      <q-icon :name="data.icon" size="sm" />
    </q-card-section>

    <q-card-section class="text-weight-medium text-h6">
      <money readonly class="my-input" v-model="data.value" v-bind="data.title.includes('%') ? percent : money" />
    </q-card-section>
  </q-card>
</template>

<script>
import { Money } from 'v-money';

export default {
  name: 'SummaryCard',
  components: { Money },
  props: {
    data: {
      require: true,
      type: [Object]
    }
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: ' R$  ',
        precision: 2,
        masked: false
      },

      percent: {
        decimal: ',',
        thousands: '.',
        prefix: ' ',
        suffix: '  %',
        precision: 2,
        masked: false
      }
    };
  }
};
</script>

<style>
.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.my-input {
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 6px 10px;
  transition: border-color 0.3s;
  width: 95%;
  height: 40px;
}

.my-input:hover {
  cursor: default;
}
</style>
