const serviceApp = process.env.VUE_APP_REPORTS_SERVICE;

export default {
  methods: {
    async getPerformanceResume(date) {
      let dateRequest = '/';

      if (date) {
        dateRequest = `?date=${date}`;
      }

      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/v2/performance${dateRequest}`);
    },

    async getSmartDashboardResume(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/summary${filter}`);
    }
  }
};
